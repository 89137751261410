
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class ShowButton extends Vue {
  @Prop({ required: true })
  value!: boolean;

  @Prop({ required: false, default: true })
  show!: boolean;
}
