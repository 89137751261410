
import { Component, Vue } from "vue-property-decorator";

import ExamReportGlobalEdit from "@/components/Core/ExamReports/Editing/ExamReportGlobalEdit.vue";

@Component({
  components: {
    ExamReportGlobalEdit,
  },
})
export default class ExamReportEditing extends Vue {
  get runId(): string | null {
    return (this.$route.params.runId && this.$route.params.runId) || null;
  }
}
