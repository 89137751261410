
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import {
  CalibrationBlockCategory,
  StepCalibrationBlockItem,
  V1CalibrationBlockItem,
  V2CalibrationBlockItem,
} from "@/domain";

import { IProceadureService } from "@/services";

import V1CalibrationBlockDisplay from "@/components/Core/ExamReports/Editing/Equipments/Childs/CalibrationBlock/V1CalibrationBlockDisplay.vue";
import V2CalibrationBlockDisplay from "@/components/Core/ExamReports/Editing/Equipments/Childs/CalibrationBlock/V2CalibrationBlockDisplay.vue";
import StepCalibrationBlockDisplay from "@/components/Core/ExamReports/Editing/Equipments/Childs/CalibrationBlock/StepCalibrationBlockDisplay.vue";
import V1CalibrationBlockAutocomplete from "@/components/General/Autocompletes/Equipments/V1CalibrationBlockAutocomplete.vue";
import V2CalibrationBlockAutocomplete from "@/components/General/Autocompletes/Equipments/V2CalibrationBlockAutocomplete.vue";
import StepCalibrationBlockAutocomplete from "@/components/General/Autocompletes/Equipments/StepCalibrationBlockAutocomplete.vue";
import Loading from "@/views/Loading.vue";

@Component({
  components: {
    V1CalibrationBlockDisplay,
    V1CalibrationBlockAutocomplete,
    V2CalibrationBlockDisplay,
    V2CalibrationBlockAutocomplete,
    StepCalibrationBlockDisplay,
    StepCalibrationBlockAutocomplete,
    Loading,
  },
  data: () => {
    return {
      CalibrationBlockCategory,
    };
  },
})
export default class CalibrationBlockAssignment extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  v1BlockId!: string;

  @Prop({ required: true })
  v2BlockId!: string;

  @Prop({ required: true })
  stepBlockId!: string;

  @Prop({ required: true })
  proceadureId!: string | null;

  get blockTypes(): { text: string; value: number }[] {
    return [
      {
        text: "V1",
        value: CalibrationBlockCategory.v1,
      },
      {
        text: "V2",
        value: CalibrationBlockCategory.v2,
      },
      {
        text: "Gradins",
        value: CalibrationBlockCategory.step,
      },
    ];
  }

  get proceadureService() {
    return this.container.resolve<IProceadureService>(S.PROCEADURE_SERVICE);
  }

  loading: boolean = false;
  blockType: CalibrationBlockCategory = CalibrationBlockCategory.unknown;
  v1Block: V1CalibrationBlockItem | null = null;
  v2Block: V2CalibrationBlockItem | null = null;
  stepBlock: StepCalibrationBlockItem | null = null;
  currentV1Id: string | null = null;
  currentV2Id: string | null = null;
  currentStepId: string | null = null;
  shadeId: string | null = null;

  mounted() {
    this.getShade();
    if (this.v1BlockId != null) {
      this.currentV1Id = this.v1BlockId;
      this.blockType = CalibrationBlockCategory.v1;
    } else if (this.v2BlockId != null) {
      this.currentV2Id = this.v2BlockId;
      this.blockType = CalibrationBlockCategory.v2;
    } else if (this.stepBlockId != null) {
      this.currentStepId = this.stepBlockId;
      this.blockType = CalibrationBlockCategory.step;
    }
  }

  async getShade() {
    try {
      this.loading = true;
      if (this.proceadureId != null) {
        const proceadure = await this.proceadureService.get(this.proceadureId);
        if (proceadure.calibrationCriteria != null) {
          this.shadeId = proceadure.calibrationCriteria.materialShade.id;
        } else {
          this.shadeId = null;
        }
      } else {
        this.shadeId = null;
      }
    } finally {
      this.loading = false;
    }
  }

  updateBlockType(blockType: number) {
    this.$emit("update:v1-block-id", null);
    this.$emit("update:v2-block-id", null);
    this.$emit("update:step-block-id", null);
    this.v1Block = null;
    this.v2Block = null;
    this.stepBlock = null;
    this.blockType = blockType;
  }

  @Watch("proceadureId")
  onIdChange = this.getShade;
}
