
import { Component, Prop, Vue } from "vue-property-decorator";

import { MeasuringRunResume, MeasuringRunStatus } from "@/domain";

import RunAutocomplete from "@/components/General/Autocompletes/ExamReports/RunAutocomplete.vue";
import StatusElement from "@/components/Core/ExamReports/Status/StatusElement.vue";
import ExamReportEditingFields from "@/components/Core/ExamReports/Editing/ExamReportEditingFields.vue";

@Component({
  components: {
    RunAutocomplete,
    StatusElement,
    ExamReportEditingFields,
  },
  data: () => {
    return {
      MeasuringRunStatus,
    };
  },
})
export default class ExamReportGlobalEdit extends Vue {
  @Prop({ required: true })
  runId!: string | null;

  runResume: MeasuringRunResume | null = null;
}
