
import { Component, Prop, Vue } from "vue-property-decorator";

import ThicknessParamsValues from "@/components/Core/ExamReports/Editing/ThicknessParams/ThicknessParamsValues.vue";

import { UpdateThicknessParamDTO } from "@/services";

@Component({
  components: {
    ThicknessParamsValues,
  },
})
export default class ThicknessParamsEdit extends Vue {
  @Prop({ required: true })
  intrados!: UpdateThicknessParamDTO;

  @Prop({ required: true })
  extrados!: UpdateThicknessParamDTO;
}
