
import { Component, Prop, Vue } from "vue-property-decorator";

import { UtGelItem } from "@/domain";

import LabeledValue from "@/components/General/LabeledValue.vue";
import UtGelAutocomplete from "@/components/General/Autocompletes/Equipments/UtGelAutocomplete.vue";

@Component({
  components: {
    UtGelAutocomplete,
    LabeledValue,
  },
})
export default class UtGelAssignment extends Vue {
  @Prop({ required: true })
  value!: string | null;

  utGel: UtGelItem | null = null;
  currentId: string | null = null;

  mounted(){
    this.currentId = this.value != null ? this.value : null;
  }
}
