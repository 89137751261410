
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { UtGelItem } from "@/domain";

import { IUtGelService } from "@/services";

@Component
export default class UtGelAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  idToInclude!: string | null;

  get utGelService() {
    return this.container.resolve<IUtGelService>(S.UT_GEL_SERVICE);
  }

  get potentials() {
    return _.filter(
      this.utGels,
      (t) => !t.disabled || t.id == this.idToInclude
    );
  }

  utGels: UtGelItem[] = [];
  loading: boolean = false;

  async mounted() {
    await this.fetch();
    this.emit(this.value);
  }

  async fetch() {
    try {
      this.loading = true;
      this.utGels = await this.utGelService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  filter(item: UtGelItem, queryText: string, itemText: string) {
    const textOne = item.serialNumber.toLowerCase();
    const textTwo = item.equipmentBrand.name.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }

  emit(id: string | null) {
    const item = _.find(this.utGels, (r) => r.id == id);
    this.$emit("input", id);
    this.$emit("show", item);
  }
}
