
import { Component, Prop, Vue } from "vue-property-decorator";

import ProceadureAutocomplete from "@/components/General/Autocompletes/ProceadureAutocomplete.vue";
import AdvisedProceadure from "@/components/Core/ExamReports/Editing/AdvisedProceadure.vue";

@Component({
  components: {
    ProceadureAutocomplete,
    AdvisedProceadure,
  },
})
export default class ProceadureEdit extends Vue {
  @Prop({ required: true })
  proceadureId!: string;

  @Prop({ required: true })
  uncertaintyProceadureId!: string;

  @Prop({ required: true })
  advisedProceadure!: string | null;

  @Prop({ required: true })
  advisedUncertaintyProceadure!: string | null;
}
