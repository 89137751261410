
import { Component, Prop, Vue } from "vue-property-decorator";

import { StepCalibrationBlockItem } from "@/domain";

import LabeledValue from "@/components/General/LabeledValue.vue";

@Component({
  components: {
    LabeledValue,
  },
})
export default class StepCalibrationBlockDisplay extends Vue {
  @Prop({ required: true })
  value!: StepCalibrationBlockItem;
}
