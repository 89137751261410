
import { Component, Prop, Vue } from "vue-property-decorator";

import { UtProbeInfos } from "@/domain";

import LabeledValue from "@/components/General/LabeledValue.vue";
import UtProbeAutocomplete from "@/components/General/Autocompletes/Equipments/UtProbeAutocompleteWrapper.vue";

@Component({
  components: {
    UtProbeAutocomplete,
    LabeledValue,
  },
})
export default class UtProbeAssignment extends Vue {
  @Prop({ required: true })
  value!: string | null;

  utProbe: UtProbeInfos | null = null;
  currentId: string | null = null;

  mounted() {
    this.currentId = this.value != null ? this.value : null;
  }
}
