
import { Component, Prop, Vue } from "vue-property-decorator";
import _ from "lodash";

import { RoughometerItem } from "@/domain";

import LabeledValue from "@/components/General/LabeledValue.vue";
import RoughometerAutocomplete from "@/components/General/Autocompletes/Equipments/RoughometerAutocomplete.vue";

@Component({
  components: {
    RoughometerAutocomplete,
    LabeledValue,
  },
})
export default class RoughometerAssignment extends Vue {
  @Prop({ required: true })
  value!: string | null;

  roughometer: RoughometerItem | null = null;
  currentId: string | null = null;

  mounted(){
    this.currentId = this.value != null ? this.value : null;
  }
}
