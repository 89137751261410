
import { Component, Prop, Vue } from "vue-property-decorator";
import { FilterSelection, FilterTypes } from "prometheus-synced-ui";

@Component({
  components: {
    FilterSelection,
  },
})
export default class FilterInputs extends Vue {
  @Prop({ required: true })
  showOffCriteria!: boolean;

  @Prop({ required: true })
  filter!: FilterTypes;
}
