
import { Component, Prop, Vue } from "vue-property-decorator";
import { lightFormat } from "date-fns";

import { ThermometerInfos } from "@/domain";

import ThermometerAutocomplete from "@/components/General/Autocompletes/Equipments/ThermometerAutocomplete.vue";
import LabeledValue from "@/components/General/LabeledValue.vue";

@Component({
  components: {
    ThermometerAutocomplete,
    LabeledValue,
  },
})
export default class ThermometerAssignment extends Vue {
  @Prop({ required: true })
  value!: string | null;

  get formattedDate(): string | null {
    return (
      (this.thermometer &&
        lightFormat(this.thermometer.validityDate, "dd/MM/yyyy")) ||
      null
    );
  }

  thermometer: ThermometerInfos | null = null;
  currentId: string | null = null;

  mounted(){
    this.currentId = this.value != null ? this.value : null;
  }
}
