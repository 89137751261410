
import { Component, Inject, Prop, Vue, Watch } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import {
  FilterTypes,
  MeasureTableDetails,
  UiMeasure,
} from "prometheus-synced-ui";

import { TableDisplay } from "prometheus-synced-ui";
import Loading from "@/views/Loading.vue";

import { IMeasureTableService } from "@/services";

@Component({
  components: {
    TableDisplay,
    Loading,
  },
})
export default class HistoricTable extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  tableId!: string;

  @Prop({ required: true })
  showOffCriteria!: string;

  @Prop({ required: true })
  filter!: FilterTypes;

  @Prop({ required: true })
  height!: string;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  get tableClass(): string {
    return `max-height: ${this.height};`;
  }

  loading: boolean = false;
  table: MeasureTableDetails | null = null;
  measures: UiMeasure[] = [];

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.table = await this.measureTableService.get(this.tableId);
      this.measures = this.table.getUiMeasures();
    } finally {
      this.loading = false;
    }
  }

  @Watch("tableId")
  onTableChange = this.fetch;
}
