
import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { StepCalibrationBlockItem } from "@/domain";

import { IStepCalibrationBlockService } from "@/services";

@Component
export default class StepCalibrationBlockAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  idToInclude!: string | null;

  @Prop({ required: true })
  shadeId!: string | null;

  get stepCalibrationBlockService() {
    return this.container.resolve<IStepCalibrationBlockService>(
      S.STEP_CALIBRATION_BLOCK_SERVICE
    );
  }

  get potentials() {
    return _.filter(
      this.stepBlocks,
      (t) => !t.disabled || t.id == this.idToInclude
    );
  }

  stepBlocks: StepCalibrationBlockItem[] = [];
  stepBlock: StepCalibrationBlockItem | null = null;
  loading: boolean = false;
  shadeRule: boolean | string = true;

  async mounted() {
    await this.fetch();
    this.emit(this.value);
  }

  async fetch() {
    try {
      this.loading = true;
      this.stepBlocks = await this.stepCalibrationBlockService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  triggerValidation() {
    this.shadeRule =
      this.stepBlock == null ||
      this.shadeId == null ||
      this.shadeId == this.stepBlock.materialShade.id ||
      "La procédure ne permet pas la nuance de l'étalon choisi";
  }

  filter(item: StepCalibrationBlockItem, queryText: string, itemText: string) {
    const textOne = item.serialNumber.toLowerCase();
    const textTwo = item.materialShade.shadeValue.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }

  emit(id: string | null) {
    const item = _.find(this.stepBlocks, (r) => r.id == id);
    this.stepBlock = item != undefined ? item : null;
    this.triggerValidation();
    this.$emit("input", id);
    this.$emit("show", item);
  }

  @Watch("shadeId")
  onShadeChange = this.triggerValidation;
}
