
import { Component, Prop, Vue } from "vue-property-decorator";

import { V2CalibrationBlockItem } from "@/domain";

import LabeledValue from "@/components/General/LabeledValue.vue";

@Component({
  components: {
    LabeledValue,
  },
})
export default class V2CalibrationBlockDisplay extends Vue {
  @Prop({ required: true })
  value!: V2CalibrationBlockItem;
}
