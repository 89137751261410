
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { RoughometerItem } from "@/domain";

import { IRoughometerService } from "@/services";

@Component
export default class RoughometerAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  idToInclude!: string | null;

  get roughometerService() {
    return this.container.resolve<IRoughometerService>(S.ROUGHOMETER_SERVICE);
  }

  get potentials(): RoughometerItem[] {
    return _.filter(
      this.roughometers,
      (t) => !t.disabled || t.id == this.idToInclude
    );
  }

  roughometers: RoughometerItem[] = [];
  loading: boolean = false;

  async mounted() {
    await this.fetch();
    this.emit(this.value);
  }

  async fetch() {
    try {
      this.loading = true;
      this.roughometers = await this.roughometerService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  filter(item: RoughometerItem, queryText: string, itemText: string) {
    const textOne = item.serialNumber.toLowerCase();
    const textTwo = item.equipmentBrand.name.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }

  emit(id: string | null) {
    const item = _.find(this.roughometers, (r) => r.id == id);
    this.$emit("input", id);
    this.$emit("show", item);
  }
}
