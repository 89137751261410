
import { Component, Prop, Vue } from "vue-property-decorator";

import ThermometerAssignment from "@/components/Core/ExamReports/Editing/Equipments/Childs/ThermometerAssignment.vue";
import RoughometerAssignment from "@/components/Core/ExamReports/Editing/Equipments/Childs/RoughometerAssignment.vue";
import UtBoardAssignment from "@/components/Core/ExamReports/Editing/Equipments/Childs/UtBoardAssignment.vue";
import UtProbeAssignment from "@/components/Core/ExamReports/Editing/Equipments/Childs/UtProbeAssignment.vue";
import UtGelAssignment from "@/components/Core/ExamReports/Editing/Equipments/Childs/UtGelAssignment.vue";
import CalibrationBlockAssignment from "@/components/Core/ExamReports/Editing/Equipments/Childs/CalibrationBlock/CalibrationBlockAssignment.vue";

@Component({
  components: {
    ThermometerAssignment,
    RoughometerAssignment,
    UtBoardAssignment,
    UtProbeAssignment,
    UtGelAssignment,
    CalibrationBlockAssignment,
  },
})
export default class EquipmentsEdit extends Vue {
  @Prop({ required: true })
  thermometerId!: string;

  @Prop({ required: true })
  roughometerId!: string;

  @Prop({ required: true })
  utBoardId!: string;

  @Prop({ required: true })
  utProbeId!: string;

  @Prop({ required: true })
  utGelId!: string;

  @Prop({ required: true })
  v1BlockId!: string;

  @Prop({ required: true })
  v2BlockId!: string;

  @Prop({ required: true })
  stepBlockId!: string;

  @Prop({ required: true })
  proceadureId!: string | null;
}
