
import { Component, Vue, Prop, Inject, Watch } from "vue-property-decorator";
import { lightFormat } from "date-fns";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { EMPTY, PROVIDER, SERVICES as S } from "@/config/literals";

import { IMeasureTableService } from "@/services";

import { MeasureTableEssential } from "@/domain";
import { TableOrigin } from "prometheus-synced-ui";

@Component({
  filters: {
    pretty(date: Date | null): string {
      return date != null ? lightFormat(date, "dd/MM/yyyy") : EMPTY;
    },
  },
  data: () => {
    return {
      TableOrigin,
    };
  },
})
export default class TableAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  idToExclude!: string | null;

  @Prop({ required: true })
  zoneId!: string | null;

  @Prop({ required: false, default: null })
  date!: Date | null;

  @Prop({ required: false, default: () => [] })
  dimensions!: number[];

  @Prop({ required: false, default: false })
  clearable!: boolean;

  @Prop({ required: false, default: "Sélection Historique" })
  label!: string;

  get measureTableService() {
    return this.container.resolve<IMeasureTableService>(
      S.MEASURE_TABLE_SERVICE
    );
  }

  get filteredTables() {
    let result: MeasureTableEssential[] = [];
    result =
      this.idToExclude != null
        ? _.filter(this.tables, (t) => t.id != this.idToExclude)
        : this.tables;

    if (this.dimensions.length > 0) {
      return _.filter(
        result,
        (t) =>
          t.containsMeasures &&
          t.columnSize == this.dimensions[0] &&
          t.lineSize == this.dimensions[1]
      );
    } else {
      return _.filter(result, (t) => t.containsMeasures);
    }
  }

  tables: MeasureTableEssential[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  getOriginLabel(origin: TableOrigin) {
    return origin == TableOrigin.technisonic ? "Technisonic" : "EDF";
  }

  async fetch() {
    try {
      this.loading = true;
      const zoneFilter = this.zoneId ? [this.zoneId] : [];
      this.tables = await this.measureTableService.getEssential({
        zoneIds: zoneFilter,
      });
    } finally {
      this.loading = false;
    }
  }

  send(tableId: string | null) {
    const index = tableId
      ? _.findIndex(this.tables, (t) => t.id == tableId)
      : -1;
    this.$emit("update:date", index != -1 ? this.tables[index].date : null);
  }

  reset() {
    this.$emit("input", null);
    this.fetch();
  }

  @Watch("zoneId")
  onPropChange = this.reset;
}
