
import { Component, Prop, Vue } from "vue-property-decorator";

import { V1CalibrationBlockItem } from "@/domain";

import LabeledValue from "@/components/General/LabeledValue.vue";

@Component({
  components: {
    LabeledValue,
  },
})
export default class V1CalibrationBlockDisplay extends Vue {
  @Prop({ required: true })
  value!: V1CalibrationBlockItem;
}
