
import {
  Component,
  Inject,
  Prop,
  Ref,
  Vue,
  Watch,
} from "vue-property-decorator";
import { PROVIDER, SERVICES as S } from "@/config/literals";
import { DependencyContainer } from "tsyringe";

import { MeasuringRunDetails, ThicknessParamInfos } from "@/domain";

import Loading from "@/views/Loading.vue";
import EquipmentsEdit from "@/components/Core/ExamReports/Editing/Equipments/EquipmentsEdit.vue";
import ProceadureEdit from "@/components/Core/ExamReports/Editing/ProceadureEdit.vue";
import ThicknessParamsEdit from "@/components/Core/ExamReports/Editing/ThicknessParams/ThicknessParamsEdit.vue";
import HistoricEdit from "@/components/Core/ExamReports/Editing/Historic/HistoricEdit.vue";

import { IMeasuringRunService, UpdateThicknessParamDTO } from "@/services";

@Component({
  components: {
    Loading,
    EquipmentsEdit,
    ProceadureEdit,
    ThicknessParamsEdit,
    HistoricEdit,
  },
})
export default class ExamReportEditingFields extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Ref("form")
  form!: any;

  @Prop({ required: true })
  runId!: string;

  get measuringRunService() {
    return this.container.resolve<IMeasuringRunService>(
      S.MEASURING_RUN_SERVICE
    );
  }

  get tableDimensions(): number[] {
    const currentTable = this.run!.currentInspection;
    return [currentTable.columnHeaders.length, currentTable.lineHeaders.length];
  }

  loading: boolean = true;
  valid: boolean = true;
  run: MeasuringRunDetails | null = null;
  thicknessParams: ThicknessParamInfos[] = [];
  thermometerId: string | null = null;
  roughometerId: string | null = null;
  utBoardId: string | null = null;
  utProbeId: string | null = null;
  utGelId: string | null = null;
  v1BlockId: string | null = null;
  v2BlockId: string | null = null;
  stepBlockId: string | null = null;
  proceadureId: string | null = null;
  uncertaintyProceadureId: string | null = null;
  firstInspectionId: string | null = null;
  lastInspectionId: string | null = null;
  intrados: UpdateThicknessParamDTO | null = null;
  extrados: UpdateThicknessParamDTO | null = null;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      if (this.runId != null) {
        this.run = await this.measuringRunService.get(this.runId);
      }
      this.reset();
    } finally {
      this.loading = false;
    }
  }

  isEmpty(thicknessParam: UpdateThicknessParamDTO | null): boolean {
    return (
      thicknessParam != null &&
      thicknessParam.calculation == null &&
      thicknessParam.nominal == null &&
      thicknessParam.characterisation == null &&
      thicknessParam.minManufactured == null
    );
  }

  async reset() {
    if (this.run != null) {
      this.thermometerId =
        this.run.analystParameters.thermometerId != null
          ? this.run.analystParameters.thermometerId
          : null;
      this.roughometerId =
        this.run.analystParameters.roughometerId != null
          ? this.run.analystParameters.roughometerId
          : null;
      this.utBoardId =
        this.run.analystParameters.utBoardId != null
          ? this.run.analystParameters.utBoardId
          : null;
      this.utProbeId =
        this.run.analystParameters.utProbeId != null
          ? this.run.analystParameters.utProbeId
          : null;
      this.utGelId =
        this.run.analystParameters.utGelId != null
          ? this.run.analystParameters.utGelId
          : null;
      this.v1BlockId =
        this.run.analystParameters.v1CalibrationBlockId != null
          ? this.run.analystParameters.v1CalibrationBlockId
          : null;
      this.v2BlockId =
        this.run.analystParameters.v2CalibrationBlockId != null
          ? this.run.analystParameters.v2CalibrationBlockId
          : null;
      this.stepBlockId =
        this.run.analystParameters.stepCalibrationBlockId != null
          ? this.run.analystParameters.stepCalibrationBlockId
          : null;
      this.proceadureId =
        this.run.analystParameters.proceadureId != null
          ? this.run.analystParameters.proceadureId
          : null;
      this.uncertaintyProceadureId =
        this.run.analystParameters.uncertaintyProceadureId != null
          ? this.run.analystParameters.uncertaintyProceadureId
          : null;
      this.intrados =
        this.run.intrados != null
          ? this.run.intrados
          : {
              nominal: null,
              minManufactured: null,
              characterisation: null,
              calculation: null,
            };
      this.extrados =
        this.run.extrados != null
          ? this.run.extrados
          : {
              nominal: null,
              minManufactured: null,
              characterisation: null,
              calculation: null,
            };
      this.firstInspectionId =
        this.run.firstInspection != null ? this.run.firstInspection.id : null;
      this.lastInspectionId =
        this.run.lastInspection != null ? this.run.lastInspection.id : null;
    }
  }

  async submit() {
    if (this.form.validate()) {
      try {
        this.loading = true;
        await this.measuringRunService.update(this.run!.id, {
          analystParameters: {
            utGelId: this.utGelId,
            utBoardId: this.utBoardId,
            utProbeId: this.utProbeId,
            roughometerId: this.roughometerId,
            thermometerId: this.thermometerId,
            v1CalibrationBlockId: this.v1BlockId,
            v2CalibrationBlockId: this.v2BlockId,
            stepCalibrationBlockId: this.stepBlockId,
            proceadureId: this.proceadureId,
            uncertaintyProceadureId: this.uncertaintyProceadureId,
          },          
          intrados:
            this.isEmpty(this.intrados) || this.intrados == null
              ? null
              : this.intrados,
          extrados:
            this.isEmpty(this.extrados) || this.extrados == null
              ? null
              : this.extrados,
          firstInspectId: this.firstInspectionId,
          lastInspectId: this.lastInspectionId,
        });
      } finally {
        this.loading = false;
        this.fetch();
      }
    }
  }

  @Watch("runId")
  onIdChange = this.fetch;
}
