
import { Component, Prop, Vue } from "vue-property-decorator";

import { UtBoardInfos } from "@/domain";

import LabeledValue from "@/components/General/LabeledValue.vue";
import UtBoardAutocomplete from "@/components/General/Autocompletes/Equipments/UtBoardAutocompleteWrapper.vue";

@Component({
  components: {
    UtBoardAutocomplete,
    LabeledValue,
  },
})
export default class RoughometerAssignment extends Vue {
  @Prop({ required: true })
  value!: string | null;

  utBoard: UtBoardInfos | null = null;
  currentId: string | null = null;

  mounted() {
    this.currentId = this.value != null ? this.value : null;
  }
}
