
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { compareAsc, isSameDay } from "date-fns";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { ThermometerItem } from "@/domain";

import { IThermometerService } from "@/services";

@Component
export default class ThermometerAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  idToInclude!: string | null;

  get thermometerService() {
    return this.container.resolve<IThermometerService>(S.THERMOMETER_SERVICE);
  }

  get potentials() {
    return _.filter(
      this.thermometers,
      (t) =>
        (!t.disabled &&
          compareAsc(t.validityDate, new Date().setHours(0, 0, 0, 0)) >= 0) ||
        t.id == this.idToInclude
    );
  }

  thermometers: ThermometerItem[] = [];
  loading: boolean = false;

  async mounted() {
    await this.fetch();
    this.emit(this.value);
  }

  async fetch() {
    try {
      this.loading = true;
      this.thermometers = await this.thermometerService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  filter(item: ThermometerItem, queryText: string, itemText: string) {
    const textOne = item.serialNumber.toLowerCase();
    const textTwo = item.equipmentBrand.name.toLowerCase();
    const searchText = queryText.toLowerCase();

    return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1;
  }

  emit(id: string | null) {
    const item = _.find(this.thermometers, (r) => r.id == id);
    this.$emit("input", id);
    this.$emit("show", item);
  }
}
