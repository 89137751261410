
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import TableAutocomplete from "@/components/General/Autocompletes/ExamReports/TableAutocomplete.vue";
import ShowButton from "@/components/Core/ExamReports/Editing/Historic/ShowButton.vue";
import HistoricTable from "@/components/Core/ExamReports/Historic/Display/HistoricTable.vue";
import FilterInputs from "@/components/General/Table/FilterInputs.vue";
import { FilterTypes } from "prometheus-synced-ui";

@Component({
  components: {
    TableAutocomplete,
    ShowButton,
    HistoricTable,
    FilterInputs,
  },
})
export default class HistoricEdit extends Vue {
  @Prop({ required: true })
  firstInspectionId!: string;

  @Prop({ required: true })
  lastInspectionId!: string;

  @Prop({ required: true })
  zoneId!: string | null;

  @Prop({ required: true })
  dimensions!: number[];

  get dateRule() {
    return (
      this.firstInspectionDate == null ||
      this.lastInspectionDate == null ||
      this.firstInspectionDate! <= this.lastInspectionDate! ||
      "La visite initiale doit avoir une date plus ancienne que la date de la dernière visite"
    );
  }

  showFirstInspection: boolean = false;
  showLastInspection: boolean = false;
  showOffCriteriaFirstInspection: boolean = true;
  showOffCriteriaLastInspection: boolean = true;
  firstInspectionDate: Date | null = null;
  lastInspectionDate: Date | null = null;
  filterFirstInspection: FilterTypes = FilterTypes.none;
  filterLastInspection: FilterTypes = FilterTypes.none;

  resetDisplay() {
    if (this.firstInspectionId == null && this.showFirstInspection) {
      this.showFirstInspection = false;
    }
    if (this.lastInspectionId == null && this.showLastInspection) {
      this.showLastInspection = false;
    }
  }

  @Watch("firstInspectionId")
  @Watch("lastInspectionId")
  onInspectionIdChange = this.resetDisplay;
}
