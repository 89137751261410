var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.value != null)?_c('div',[_c('v-text-field',{attrs:{"value":_vm.value.nominal,"type":"number","label":"Nominale","suffix":"mm","rules":[
      (v) => v == null || v > 0 || 'Ce champ doit être strictement positif',
    ]},on:{"input":function($event){return _vm.updateNominal($event)}}}),_c('v-text-field',{attrs:{"value":_vm.value.minManufactured,"type":"number","label":"Mini. de Fabrication","suffix":"mm","rules":[
      (v) => v == null || v > 0 || 'Ce champ doit être strictement positif',
    ]},on:{"input":function($event){return _vm.updateManufactured($event)}}}),_c('v-text-field',{attrs:{"value":_vm.value.characterisation,"type":"number","rules":[
      _vm.characterisationRule,
      (v) => v == null || v > 0 || 'Ce champ doit être strictement positif',
    ],"label":"Caractérisation","suffix":"mm"},on:{"input":function($event){return _vm.updateCharacterisation($event)}}}),_c('v-text-field',{attrs:{"value":_vm.value.calculation,"type":"number","label":"Calcul","suffix":"mm","rules":[
      (v) => v == null || v > 0 || 'Ce champ doit être strictement positif',
    ]},on:{"input":function($event){return _vm.updateCalculation($event)}}})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }