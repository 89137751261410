
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class LabeledValue extends Vue {
  @Prop({ required: true })
  label!: string;

  @Prop({ required: true })
  value!: string;
}
