
import _ from "lodash";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import { UpdateThicknessParamDTO } from "@/services";

@Component
export default class ThicknessParamsValues extends Vue {
  @Prop({ required: true })
  value!: UpdateThicknessParamDTO;

  @Prop({ required: true })
  characterisation!: number | null;

  characterisationRule: boolean | string =
    "Une des deux épaisseurs de caractérisation doit être renseignée";

  mounted() {
    this.triggerValidation();
  }

  triggerValidation() {
    this.characterisationRule =
      this.characterisation != null || this.value.characterisation != null
        ? true
        : "Une des deux épaisseurs de caractérisation doit être renseignée";
  }

  updateNominal(value: number) {
    const intrados = _.cloneDeep(this.value);
    intrados!.nominal = (value && value) || null;
    this.$emit("input", intrados);
  }

  updateManufactured(value: number) {
    const intrados = _.cloneDeep(this.value);
    intrados!.minManufactured = (value && value) || null;
    this.$emit("input", intrados);
  }

  updateCharacterisation(value: number) {
    const intrados = _.cloneDeep(this.value);
    intrados!.characterisation = (value && value) || null;
    this.$emit("input", intrados);
  }

  updateCalculation(value: number) {
    const intrados = _.cloneDeep(this.value);
    intrados!.calculation = (value && value) || null;
    this.$emit("input", intrados);
  }

  @Watch("characterisation")
  @Watch("value")
  onPropChange = this.triggerValidation;
}
