
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import { DependencyContainer } from "tsyringe";
import _ from "lodash";

import { PROVIDER, SERVICES as S } from "@/config/literals";

import { UtProbeItem } from "prometheus-synced-ui";
import { UtProbeAutocomplete } from "prometheus-synced-ui";

import { IUtProbeService } from "@/services";

@Component({ components: { UtProbeAutocomplete } })
export default class UtProbeAutocompleteWrapper extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: false, default: null })
  idToInclude!: string | null;

  get utProbeService() {
    return this.container.resolve<IUtProbeService>(S.UT_PROBE_SERVICE);
  }

  get potentials() {
    return _.filter(
      this.utProbes,
      (t) => !t.disabled || t.id == this.idToInclude
    );
  }

  utProbes: UtProbeItem[] = [];
  loading: boolean = false;

  async mounted() {
    await this.fetch();
    this.emit(this.value);
  }

  async fetch() {
    try {
      this.loading = true;
      this.utProbes = await this.utProbeService.getMany({
        ids: [],
        includeDisabled: true,
        includeEnabled: true,
      });
    } finally {
      this.loading = false;
    }
  }

  filter(item: UtProbeItem, queryText: string, itemText: string) {
    if (item instanceof UtProbeItem) {
      const textOne = item.serialNumber.toLowerCase();
      const textTwo = item.equipmentBrand.name.toLowerCase();
      const textThree = item.equipmentType.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 ||
        textTwo.indexOf(searchText) > -1 ||
        textThree.indexOf(searchText) > -1
      );
    }
    return true;
  }

  emit(id: string | null) {
    const item = _.find(this.utProbes, (r) => r.id == id);
    this.$emit("input", id);
    this.$emit("show", item);
  }
}
