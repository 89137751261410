
import { Component, Vue, Prop, Inject } from "vue-property-decorator";
import _ from "lodash";
import { DependencyContainer } from "tsyringe";
import { PROVIDER, SERVICES as S } from "@/config/literals";

import { IProceadureService } from "@/services";

import { ProceadureInfos } from "@/domain";

@Component
export default class ProceadureAutocomplete extends Vue {
  @Inject(PROVIDER)
  container!: DependencyContainer;

  @Prop({ required: true })
  value!: string | null;

  @Prop({ required: true })
  label!: string | null;

  @Prop({ required: true })
  clearable!: boolean;

  @Prop({ required: false, default: null })
  idToInclude!: string | null;

  get proceadureService() {
    return this.container.resolve<IProceadureService>(S.PROCEADURE_SERVICE);
  }

  get filteredProceadures() {
    const proceadures = _.filter(
      this.proceadures,
      (s) => !s.disabled || s.id == this.idToInclude
    );

    return _.map(proceadures, (p) => {
      return {
        id: p.id,
        name: p.name,
      };
    });
  }

  proceadures: ProceadureInfos[] = [];
  loading: boolean = false;

  mounted() {
    this.fetch();
  }

  async fetch() {
    try {
      this.loading = true;
      this.proceadures = await this.proceadureService.getMany({
        ids: [],
        includeEnabled: true,
        includeDisabled: true,
      });
    } finally {
      this.loading = false;
    }
  }
}
